<template>
  <b-row class="page-notifications">
    <b-col>
      <PageHeader :title="$t('general.notificationsPage.title')" />

      <div class="page-notifications__settings">
        <div
          class="page-notifications__row"
          v-for="button in buttonList"
          :key="button.id"
        >
          <div class="page-notifications__text">
            <span class="page-notifications__font">
              {{ button.name }}
            </span>
          </div>

          <span class="page-notifications__addition">
            <CButtonToggle
              class="page-notifications__toggle"
              :is-active="button.isActive"
              @click="button.action"
            />
          </span>
        </div>
      </div>

      <div class="page-notifications__button-wrapper">
        <button
          type="button"
          @click.prevent="() => {}"
          class="base-button page-notifications__button"
        >
          <span class="base-button__font">
            {{ $t("general.notificationsPage.button.update") }}
          </span>
        </button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import CButtonToggle from "@/features/ui/CButtonToggle.vue";

export default {
  name: "PageSettingsNotifications",
  components: {
    CButtonToggle,
    PageHeader,
  },
  data() {
    return {
      buttonList: [
        {
          id: 1,
          name: this.$t("general.notificationsPage.button.email"),
          isActive: false,
          action: () =>
            (this.buttonList[0].isActive = !this.buttonList[0].isActive),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.page-notifications {
  $parent: &;

  &__settings {
    margin-top: em(24);
    margin-left: em(-15);
    margin-right: em(-15);
    padding: 0 em(17);
    border-bottom: 1px solid $app-gray-2;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: em(19);

    &:last-of-type {
      margin-bottom: em(31);
    }
  }

  &__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color $time-normal $ease, color $time-normal $ease;
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
  }

  &__addition {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
  }

  &__button {
    margin-top: em(17);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
  }

  &__toggle {
    font-size: 60%; // change toggle default size
  }

  &__icon {
    display: inline-block;
    width: em(18);
    min-width: em(18);
    height: em(18);
    margin-right: em(13);
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
